h4 {
    color: #2c3e50;
    text-align: left;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

p {
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

li {
    margin-bottom: 10px;
    font-family:"Poppins", sans-serif;
}
.privacy-txt {
    text-align: left;
}
.first-title {
    color: #333;
    line-height: 1.3;
}

.custom-list {
    list-style: none;
    padding-left: 20px;
}

.custom-list li {
    position: relative;
    margin-bottom: 8px;
}

.custom-list li::before {
    content: "•";
    color: #333;
    position: absolute;
    left: -10px;
    font-size: 16px;
    line-height: 1.5;
}